import {useEffect} from 'react';
import {useRouter} from 'next/navigation';
import {StoreWithFollowers} from '@delorand/db/schema/store';
import {ConvertDateToString} from '@delorand/schema/entities/shared';
import {useStore} from 'c/store/store';

interface FollowProps {
  userId: number | undefined;
  store: ConvertDateToString<StoreWithFollowers>;
  className?: string;
  onToggle: () => void;
}

export function Follow({userId, store, className, onToggle}: FollowProps) {
  const router = useRouter();
  const onAfterAuth = useStore(state => state.onAfterAuth);

  const following = useStore(state => state.following);
  const updateFollowing = useStore(state => state.updateFollowing);

  useEffect(() => {
    if (store.followers.some(x => x.customerId === userId)) {
      updateFollowing(store.id);
    }
  }, [userId]);

  const follow = () => {
    if (!userId) {
      onAfterAuth(() => {
        updateFollowing(store.id);
        onToggle();
      });
      router.push('/login');
    } else {
      updateFollowing(store.id);
      onToggle();
    }
  };

  return (
    <>
      <button
        // disabled={following}
        className={className || 'border-b px-2 py-1 text-sm text-white'}
        onClick={follow}
      >
        {following.includes(store.id) ? 'Following' : 'Follow'}
      </button>
    </>
  );
}
