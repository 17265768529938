import {cartModS, SToType} from '@delorand/schema/dto/shared';
import {client} from 'c/utils/api';
import useSwr, {useSWRConfig} from 'swr';
import useSWRMutation from 'swr/mutation';
import {useStore} from '../store/store';
import {fetchApi} from './misc';

export const useCart = () => {
  const set_scs_state = useStore(state => state.set_scs_state);

  const {data, isLoading} = useSwr(
    ['cart'],
    () => fetchApi(client.cart.$get()),
    {
      revalidateOnReconnect: false,
      onSuccess: ({data}) => {
        set_scs_state(data?.storesCarriers ?? []);
      },
      onError: () => {
        set_scs_state([]);
      },
    }
  );

  return {
    cartItems: data?.data?.items ?? [],
    cart: data,
    isLoading,
  };
};

export type CartReturn = Awaited<ReturnType<typeof useCart>>;

export const useModifyCart = () => {
  const {mutate} = useSWRConfig();
  const {cartItems, cart} = useCart();

  const S = cartModS.partial();

  const res = useSWRMutation(
    ['cart.modify'],
    async (_, {arg}: {arg: SToType<typeof S>}) => {
      if (arg.add) {
        return fetchApi(client.cart.add.$patch({json: arg.add}));
      }
      if (arg.update) {
        return fetchApi(client.cart.update.$patch({json: arg.update}));
      }

      if (arg.remove) {
        return fetchApi(client.cart.remove.$patch({json: arg.remove}));
      }
      return null;
    },
    {
      onSuccess: res => {
        if (!res?.data) return;
        const placeholder = cart ?? {
          updatedAt: new Date(),
        };
        mutate(['cart'], {
          ...placeholder,
          items: [
            ...res.data,
            ...cartItems.filter(
              x => !res?.data?.some(y => y.variantId === x.variantId)
            ),
          ],
        });
      },
    }
  );

  return {updateCart: res.trigger, isUpdatingCart: res.isMutating};
};
