import {shippingAddresses} from '@delorand/db/schema/customer';
import {checkouts, orders} from '@delorand/db/schema/order';
import {createInsertSchema} from 'drizzle-zod';
import {z, ZodTypeAny} from 'zod';

export type SToType<T extends ZodTypeAny> = z.infer<T>;

export const statusEnum = z.enum(['ACTIVE', 'DISABLED']);

export const rawFileS = z.object({
  serverUrl: z.string().nullish(),
  file: z.any().nullish(),
  previewUrl: z.string().nullish(),
});

const fileSchema = rawFileS.refine(
  schema => (schema.file || schema.serverUrl ? true : false),
  {
    message: 'Select an image',
  }
);

export const shippingAddressesS = createInsertSchema(shippingAddresses).omit({
  default: true,
  customerId: true,
});

export type ShippingDetailsS = z.infer<typeof shippingAddressesS>;

export const scsS = z.array(
  z.object({
    storeId: z.number(),
    cid: z.number(),
  })
);

export const checkoutItemS = z.object({
  variantId: z.number(),
  quantity: z.number(),
});

export const checkoutS = createInsertSchema(checkouts).extend({
  items: z.array(checkoutItemS),
  storesCarriers: scsS,
  // shippingAddresses: shippingAddressesS,
});

export const placeOrderS = z.object({
  checkoutId: z.number(),
  items: z.array(
    z.object({
      variantId: z.number(),
      quantity: z.number(),
    })
  ),
  deliveryDetails: shippingAddressesS,
  storesCarriers: scsS,
  couponIds: z.array(z.number()),
});

export const itemsSortS = z
  .enum(['highest-price', 'lowest-price', 'orders', 'best-match'])
  .nullish()
  .optional();

export const verifyS = z.object({
  reference: z.string(),
});

export const cartModS = z.object({
  add: z.object({
    items: z.array(
      z.object({
        quantity: z.number().optional(),
        variantId: z.number(),
      })
    ),
    storesCarriers: z.array(
      z.object({
        storeId: z.number(),
        cid: z.number(),
      })
    ),
  }),
  remove: z.object({
    type: z.enum(['ONE', 'MANY', 'ALL']),
    variantIds: z.array(z.number()),
  }),
  update: z.object({
    variantId: z.number(),
    type: z.enum(['INCR', 'DECR']),
    quantity: z.number().optional(),
  }),
});

export const categoryS = z.object({
  name: z.string().min(1, 'required').max(200, 'invalid'),
  slug: z.string().min(1, 'required').max(200, 'invalid').toLowerCase(),
  level: z.number().min(1, 'required').max(200, 'invalid'),
  minPrice: z.number(),
  maxPrice: z.number(),
  commission: z.number().nullish(),
  disabled: z.boolean().optional(),
});
export const emailS = z.string().email('Enter a valid email');

export const brandS = z.object({
  name: z.string().toLowerCase(),
});

export const changePasswordS = z.object({
  old: z.string().min(6),
  new: z.string().min(6),
  confirm: z.string().min(6),
});

export const groupCartS = z.object({
  name: z.string().min(1, 'Enter a valid name'),
  items: z.array(z.string()),
  members: z.array(z.string()).min(1, 'you must add at least one friend'),
});

export const addToGroupCartS = z.object({
  groupCartId: z.string(),
  addedById: z.string(),
  item: z.object({
    id: z.string(),
    quantity: z.number(),
  }),
});

export const searchCollectS = z.object({
  hint: z.string(),
  clickedProductId: z.number().optional(),
});

export const updateShipmentS = z.object({
  carrierUsedId: z.number().min(1, 'Enter Carrier Used'),
  trackingCode: z.string().optional(),
  ETA: z.object({
    from: z.date(),
    to: z.date(),
  }),
  cost: z.number().optional(),
  comments: z.string().optional(),
});

export const notificationTypeS = z.enum([
  'order',
  'support',
  'complaint',
  'all',
]);
export const notificationStatus = z.enum(['sealed', 'opened']);

export const storeMessageS = z.object({
  type: z.enum(['TEXT', 'IMAGE', 'VIDEO', 'COUPON']),
  messageText: z.string(),
  mediaURL: z.string().optional(),
  couponId: z.number().optional(),
});

export const promotionS = z.object({
  type: z.enum(['IMAGE', 'PRODUCTS', 'STORE']),
  image: rawFileS.optional(),
  productIds: z.array(z.number()).optional(),
  links: z
    .array(
      z.object({
        id: z.string(),
        url: z.string(),
      })
    )
    .optional(),
  priority: z.number().default(1),
  tags: z.array(z.string()).optional(),
  title: z.string().optional(),
  countDownTo: z.date().or(z.string()).optional(),
});

export const productPromotionS = promotionS
  .pick({
    priority: true,
    productIds: true,
  })
  .extend({
    title: z.string(),
    expiresIn: z.number().optional(),
  });

export const imagePromotionS = z.object({
  image: fileSchema,
  productId: z.string().optional(),
  link: z.string().optional(),
  priority: z.number().default(1),
});

export const contactS = z.object({
  name: z.string().optional(),
  email: z.string().email().min(1, 'Required'),
  subject: z.string().min(1, 'Required'),
  message: z.string().min(1, 'Required'),
});

export const penaltyS = z.object({
  storeId: z.string().min(1, 'Required'),
  orderId: z.string().optional(),
  productId: z.string().min(1, 'Required'),
  violationType: z.enum(['']),
  action: z.enum(['']),
  violatedAt: z.date(),
  description: z.string(),
  value: z.number().default(0),
  comments: z.string(),
});

export const orderUpdateS = createInsertSchema(orders);

export * from './product';
export * from './vendor';
